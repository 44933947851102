import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import appHeaderText from "./app-header/text";
import promoDescrText from "./promo/promo_descr/text";
import promoButtonText from "./promo/promo_buttons/text"
import likeDescrText from "./promo/like_section/like_descr/text";
import servicesCaseText from "./services_cases/text";
import servisesCaseTab from "./services_cases/services_cases_list/text";
import servicesContent from "./services_cases/services_content/text";
import casesContent from "./services_cases/cases_content/text";
import aboutHeader from "./about/text";
import crewContent from "./crew/text";
import aboutNumbers from "./about/numbers_about/text";
import aboutInformation from "./about/about_inform/text";
import formText from "./form/text";
import footer from "./footer/text";
import menu from "./menu/text";

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: "ru",
        resources: {
            eng: {

                translation: {
                    aplicationBtn: appHeaderText.eng.aplicationBtn,
                    langBtn: appHeaderText.eng.langBtn,
                    promoDescr: promoDescrText.eng.promoDescr,
                    promoMoto: promoDescrText.eng.promoMoto,
                    projectBtn: promoButtonText.eng.projectBtn,
                    portfolioBtn: promoButtonText.eng.portfolioBtn,
                    likeDescrLike: likeDescrText.eng.likeDescrLike,
                    likeDescr: likeDescrText.eng.likeDescr,
                    servicesCaseHeader: servicesCaseText.eng.servicesCaseHeader,
                    servisesCaseTab: {
                        tab1: servisesCaseTab.eng.tab1,
                        tab2: servisesCaseTab.eng.tab2,
                        tab3: servisesCaseTab.eng.tab3,
                        tab4: servisesCaseTab.eng.tab4,
                        tab5: servisesCaseTab.eng.tab5,
                    },
                    servicesContent: {
                        service1: {
                            serviceDescription: servicesContent.eng.service1.serviceDescription,
                            serviceList: {
                                item1: servicesContent.eng.service1.serviceList.item1,
                                item2: servicesContent.eng.service1.serviceList.item2,
                                item3: servicesContent.eng.service1.serviceList.item3,
                                item4: servicesContent.eng.service1.serviceList.item4,
                                item5: servicesContent.eng.service1.serviceList.item5,
                                item6: servicesContent.eng.service1.serviceList.item6,
                                item7: servicesContent.eng.service1.serviceList.item7,
                                item8: servicesContent.eng.service1.serviceList.item8,
                                item9: servicesContent.eng.service1.serviceList.item9,
                                item10: servicesContent.eng.service1.serviceList.item10,
                                item11: servicesContent.eng.service1.serviceList.item11
                            }
                        },
                        service2: {
                            serviceDescription: servicesContent.eng.service2.serviceDescription,
                            serviceList: {
                                item1: servicesContent.eng.service2.serviceList.item1,
                                item2: servicesContent.eng.service2.serviceList.item2,
                                item3: servicesContent.eng.service2.serviceList.item3,
                                item4: servicesContent.eng.service2.serviceList.item4,
                                item5: servicesContent.eng.service2.serviceList.item5,
                                item6: servicesContent.eng.service2.serviceList.item6,
                                item7: servicesContent.eng.service2.serviceList.item7,
                                item8: servicesContent.eng.service2.serviceList.item8,
                                item9: servicesContent.eng.service2.serviceList.item9,
                                item10: servicesContent.eng.service2.serviceList.item10,
                                item11: servicesContent.eng.service2.serviceList.item11,
                                item12: servicesContent.eng.service2.serviceList.item12,
                                item13: servicesContent.eng.service2.serviceList.item13,
                                item14: servicesContent.eng.service2.serviceList.item14,
                                item15: servicesContent.eng.service2.serviceList.item15
                            }
                        },
                        service3: {
                            serviceDescription: servicesContent.eng.service3.serviceDescription,
                            serviceList: {
                                item1: servicesContent.eng.service3.serviceList.item1,
                                item2: servicesContent.eng.service3.serviceList.item2,
                                item3: servicesContent.eng.service3.serviceList.item3,
                                item4: servicesContent.eng.service3.serviceList.item4,
                                item5: servicesContent.eng.service3.serviceList.item5,
                                item6: servicesContent.eng.service3.serviceList.item6,
                                item7: servicesContent.eng.service3.serviceList.item7,
                            }
                            // servicePlan: {
                            //     item1: servicesContent.eng.service3.servicePlan.item1,
                            //     item2: servicesContent.eng.service3.servicePlan.item2,
                            //     item3: servicesContent.eng.service3.servicePlan.item3,
                            //     item4: servicesContent.eng.service3.servicePlan.item4,
                            //     item5: servicesContent.eng.service3.servicePlan.item5
                            // }
                        },
                        service4: {
                            serviceDescription: servicesContent.eng.service4.serviceDescription,
                            serviceList: {
                                item1: servicesContent.eng.service4.serviceList.item1,
                                item2: servicesContent.eng.service4.serviceList.item2,
                                item3: servicesContent.eng.service4.serviceList.item3,
                                item4: servicesContent.eng.service4.serviceList.item4,
                                item5: servicesContent.eng.service4.serviceList.item5,
                                item6: servicesContent.eng.service4.serviceList.item6,
                                item7: servicesContent.eng.service4.serviceList.item7,
                                item8: servicesContent.eng.service4.serviceList.item8,
                                item9: servicesContent.eng.service4.serviceList.item9,
                                item10: servicesContent.eng.service4.serviceList.item10,
                                item11: servicesContent.eng.service4.serviceList.item11,
                            }
                        },
                        service5: {
                            serviceDescription: servicesContent.eng.service5.serviceDescription,
                            serviceList: {
                                item1: servicesContent.eng.service5.serviceList.item1,
                                item2: servicesContent.eng.service5.serviceList.item2,
                                item3: servicesContent.eng.service5.serviceList.item3,
                                item4: servicesContent.eng.service5.serviceList.item4,
                                item5: servicesContent.eng.service5.serviceList.item5,
                                item6: servicesContent.eng.service5.serviceList.item6
                            }
                        },

                    },
                    about: {
                        aboutHeader: aboutHeader.eng.aboutHeader,
                        aboutNumbers: {
                            block1: {
                                number: aboutNumbers.eng.block1.number,
                                desc: aboutNumbers.eng.block1.desc
                            },
                            block2: {
                                number: aboutNumbers.eng.block2.number,
                                desc: aboutNumbers.eng.block2.desc
                            },
                            block3: {
                                number: aboutNumbers.eng.block3.number,
                                desc: aboutNumbers.eng.block3.desc
                            }
                        },
                        aboutDesc: aboutInformation.eng.aboutDesc,
                        aboutAdventage1: aboutInformation.eng.adventage1,
                        aboutAdventage2: aboutInformation.eng.adventage2,
                        aboutAdventage3: aboutInformation.eng.adventage3
                    },
                    crew: {
                        crewHeader: crewContent.eng.crewHeader,
                        crewDesc1: crewContent.eng.crewDesc1,
                        crewDesc2: crewContent.eng.crewDesc2
                    },

                    formText: {
                        formHeader: formText.eng.formHeader,
                        name: formText.eng.name,
                        phone: formText.eng.phone,
                        mail: formText.eng.mail,
                        comment: formText.eng.comment,
                        confidatial: formText.eng.confidatial,
                        submit: formText.eng.submit
                    },
                    footer: {
                        nav_1: footer.eng.nav_1,
                        nav_2: footer.eng.nav_2,
                        nav_3: footer.eng.nav_3,
                        mail: footer.eng.mail,
                        tel: footer.eng.tel,
                    },
                    menu: {
                        menu_item_1: menu.eng.menu_item_1,
                        menu_item_2: menu.eng.menu_item_2,
                        menu_item_3: menu.eng.menu_item_3,
                        menu_item_4: menu.eng.menu_item_4,
                        menu_item_5: menu.eng.menu_item_5
                    },
                    casesContent: {
                        tab_1: {
                            case_1: {
                                header: casesContent.eng.web.case_1.header,
                                about: casesContent.eng.web.case_1.about,
                                profile: casesContent.eng.web.case_1.profile,
                                description: casesContent.eng.web.case_1.description,
                                goals: casesContent.eng.web.case_1.goals,
                                results: casesContent.eng.web.case_1.results,
                                total: casesContent.eng.web.case_1.total,
                                logo: casesContent.eng.web.case_1.logo,
                                creo_1: casesContent.eng.web.case_1.creo_1,
                                creo_2: casesContent.eng.web.case_1.creo_2,
                            },
                            case_2: {
                                header: casesContent.eng.web.case_2.header,
                                about: casesContent.eng.web.case_2.about,
                                profile: casesContent.eng.web.case_2.profile,
                                description: casesContent.eng.web.case_2.description,
                                goals: casesContent.eng.web.case_2.goals,
                                results: casesContent.eng.web.case_2.results,
                                total: casesContent.eng.web.case_2.total,
                                logo: casesContent.eng.web.case_2.logo,
                                creo_1: casesContent.eng.web.case_2.creo_1,
                                creo_2: casesContent.eng.web.case_2.creo_2,
                            },
                            case_3: {
                                header: casesContent.eng.web.case_3.header,
                                about: casesContent.eng.web.case_3.about,
                                profile: casesContent.eng.web.case_3.profile,
                                description: casesContent.eng.web.case_3.description,
                                goals: casesContent.eng.web.case_3.goals,
                                results: casesContent.eng.web.case_3.results,
                                total: casesContent.eng.web.case_3.total,
                                logo: casesContent.eng.web.case_3.logo,
                                creo_1: casesContent.eng.web.case_3.creo_1,
                            }




                        },
                        tab_2: {
                            case_1: {
                                header: casesContent.eng.design.case_1.header,
                                about: casesContent.eng.design.case_1.about,
                                profile: casesContent.eng.design.case_1.profile,
                                description: casesContent.eng.design.case_1.description,
                                goals: casesContent.eng.design.case_1.goals,
                                results: casesContent.eng.design.case_1.results,
                                total: casesContent.eng.design.case_1.total,
                                logo: casesContent.eng.design.case_1.logo,
                                creo_1: casesContent.eng.design.case_1.creo_1,

                            },
                            case_2: {
                                header: casesContent.eng.design.case_2.header,
                                about: casesContent.eng.design.case_2.about,
                                profile: casesContent.eng.design.case_2.profile,
                                description: casesContent.eng.design.case_2.description,
                                goals: casesContent.eng.design.case_2.goals,
                                results: casesContent.eng.design.case_2.results,
                                total: casesContent.eng.design.case_2.total,
                                logo: casesContent.eng.design.case_2.logo,
                                creo_1: casesContent.eng.design.case_2.creo_1,

                            },
                            case_3: {
                                header: casesContent.eng.design.case_3.header,
                                about: casesContent.eng.design.case_3.about,
                                profile: casesContent.eng.design.case_3.profile,
                                description: casesContent.eng.design.case_3.description,
                                goals: casesContent.eng.design.case_3.goals,
                                results: casesContent.eng.design.case_3.results,
                                total: casesContent.eng.design.case_3.total,
                                logo: casesContent.eng.design.case_3.logo,
                            }
                        },
                        tab_3: {
                            case_1: {
                                header: casesContent.eng.smm.case_1.header,
                                about: casesContent.eng.smm.case_1.about,
                                profile: casesContent.eng.smm.case_1.profile,
                                description: casesContent.eng.smm.case_1.description,
                                goals: casesContent.eng.smm.case_1.goals,
                                results: casesContent.eng.smm.case_1.results,
                                total: casesContent.eng.smm.case_1.total,
                                logo: casesContent.eng.smm.case_1.logo,
                                creo_1: casesContent.eng.smm.case_1.creo_1,
                                creo_2: casesContent.eng.smm.case_1.creo_2,
                            },
                            
                            case_2: {
                                header: casesContent.eng.smm.case_2.header,
                                about: casesContent.eng.smm.case_2.about,
                                profile: casesContent.eng.smm.case_2.profile,
                                description: casesContent.eng.smm.case_2.description,
                                goals: casesContent.eng.smm.case_2.goals,
                                results: casesContent.eng.smm.case_2.results,
                                total: casesContent.eng.smm.case_2.total,
                                logo: casesContent.eng.smm.case_2.logo,
                                creo_1: casesContent.eng.smm.case_2.creo_1,
                                creo_2: casesContent.eng.smm.case_2.creo_2,
                            },
                            
                            case_3: {
                                header: casesContent.eng.smm.case_3.header,
                                about: casesContent.eng.smm.case_3.about,
                                profile: casesContent.eng.smm.case_3.profile,
                                description: casesContent.eng.smm.case_3.description,
                                goals: casesContent.eng.smm.case_3.goals,
                                results: casesContent.eng.smm.case_3.results,
                                total: casesContent.eng.smm.case_3.total,
                                logo: casesContent.eng.smm.case_3.logo,
                                creo_1: casesContent.eng.smm.case_3.creo_1,
                            },
                        },
                        tab_4: {
                            case_1: {
                                header: casesContent.eng.adv.case_1.header,
                                about: casesContent.eng.adv.case_1.about,
                                profile: casesContent.eng.adv.case_1.profile,
                                description: casesContent.eng.adv.case_1.description,
                                goals: casesContent.eng.adv.case_1.goals,
                                results: casesContent.eng.adv.case_1.results,
                                total: casesContent.eng.adv.case_1.total,
                                logo: casesContent.eng.adv.case_1.logo,
                                creo_1: casesContent.eng.adv.case_1.creo_1,
                                creo_2: casesContent.eng.adv.case_1.creo_2,
                            },
                            
                            case_2: {
                                header: casesContent.eng.adv.case_2.header,
                                about: casesContent.eng.adv.case_2.about,
                                profile: casesContent.eng.adv.case_2.profile,
                                description: casesContent.eng.adv.case_2.description,
                                goals: casesContent.eng.adv.case_2.goals,
                                results: casesContent.eng.adv.case_2.results,
                                total: casesContent.eng.adv.case_2.total,
                                logo: casesContent.eng.adv.case_2.logo,
                                creo_1: casesContent.eng.adv.case_2.creo_1,
                            }
                        },
                        tab_5: {
                            case_1: {
                                header: casesContent.eng.video.case_1.header,
                                about: casesContent.eng.video.case_1.about,
                                profile: casesContent.eng.video.case_1.profile,
                                description: casesContent.eng.video.case_1.description,
                                goals: casesContent.eng.video.case_1.goals,
                                results: casesContent.eng.video.case_1.results,
                                total: casesContent.eng.video.case_1.total,
                                logo: casesContent.eng.video.case_1.logo,
                                creo_1: casesContent.eng.video.case_1.creo_1,
                            },
                            case_2: {
                                header: casesContent.eng.video.case_2.header,
                                about: casesContent.eng.video.case_2.about,
                                profile: casesContent.eng.video.case_2.profile,
                                description: casesContent.eng.video.case_2.description,
                                goals: casesContent.eng.video.case_2.goals,
                                results: casesContent.eng.video.case_2.results,
                                total: casesContent.eng.video.case_2.total,
                                logo: casesContent.eng.video.case_2.logo,
                                creo_1: casesContent.eng.video.case_2.creo_1,
                            },
                            case_3: {
                                header: casesContent.eng.video.case_3.header,
                                about: casesContent.eng.video.case_3.about,
                                profile: casesContent.eng.video.case_3.profile,
                                description: casesContent.eng.video.case_3.description,
                                goals: casesContent.eng.video.case_3.goals,
                                results: casesContent.eng.video.case_3.results,
                                total: casesContent.eng.video.case_3.total,
                                logo: casesContent.eng.video.case_3.logo,
                                creo_1: casesContent.eng.video.case_3.creo_1,
                                creo_2: casesContent.eng.video.case_3.creo_2,
                            }
                        },
                        headers: {
                            summary: casesContent.eng.headers.summary,
                            profile: casesContent.eng.headers.profile,
                            description: casesContent.eng.headers.description,
                            goals: casesContent.eng.headers.goals,
                            results: casesContent.eng.headers.results,
                            total: casesContent.eng.headers.total,
                            casesDetails: casesContent.eng.headers.casesDetails
                        }

                    }
                }
            },
            ru: {
                translation: {
                    aplicationBtn: appHeaderText.ru.aplicationBtn,
                    langBtn: appHeaderText.ru.langBtn,
                    promoDescr: promoDescrText.ru.promoDescr,
                    promoMoto: promoDescrText.ru.promoMoto,
                    projectBtn: promoButtonText.ru.projectBtn,
                    portfolioBtn: promoButtonText.ru.portfolioBtn,
                    likeDescrLike: likeDescrText.ru.likeDescrLike,
                    likeDescr: likeDescrText.ru.likeDescr,
                    servicesCaseHeader: servicesCaseText.ru.servicesCaseHeader,
                    servisesCaseTab: {
                        tab1: servisesCaseTab.ru.tab1,
                        tab2: servisesCaseTab.ru.tab2,
                        tab3: servisesCaseTab.ru.tab3,
                        tab4: servisesCaseTab.ru.tab4,
                        tab5: servisesCaseTab.ru.tab5,
                    },
                    servicesContent: {
                        service1: {
                            serviceDescription: servicesContent.ru.service1.serviceDescription,
                            serviceList: {
                                item1: servicesContent.ru.service1.serviceList.item1,
                                item2: servicesContent.ru.service1.serviceList.item2,
                                item3: servicesContent.ru.service1.serviceList.item3,
                                item4: servicesContent.ru.service1.serviceList.item4,
                                item5: servicesContent.ru.service1.serviceList.item5,
                                item6: servicesContent.ru.service1.serviceList.item6,
                                item7: servicesContent.ru.service1.serviceList.item7,
                                item8: servicesContent.ru.service1.serviceList.item8,
                                item9: servicesContent.ru.service1.serviceList.item9,
                                item10: servicesContent.ru.service1.serviceList.item10,
                                item11: servicesContent.ru.service1.serviceList.item11
                            }
                        },
                        service2: {
                            serviceDescription: servicesContent.ru.service2.serviceDescription,
                            serviceList: {
                                item1: servicesContent.ru.service2.serviceList.item1,
                                item2: servicesContent.ru.service2.serviceList.item2,
                                item3: servicesContent.ru.service2.serviceList.item3,
                                item4: servicesContent.ru.service2.serviceList.item4,
                                item5: servicesContent.ru.service2.serviceList.item5,
                                item6: servicesContent.ru.service2.serviceList.item6,
                                item7: servicesContent.ru.service2.serviceList.item7,
                                item8: servicesContent.ru.service2.serviceList.item8,
                                item9: servicesContent.ru.service2.serviceList.item9,
                                item10: servicesContent.ru.service2.serviceList.item10,
                                item11: servicesContent.ru.service2.serviceList.item11,
                                item12: servicesContent.ru.service2.serviceList.item12,
                                item13: servicesContent.ru.service2.serviceList.item13,
                                item14: servicesContent.ru.service2.serviceList.item14,
                                item15: servicesContent.ru.service2.serviceList.item15
                            }
                        },
                        service3: {
                            serviceDescription: servicesContent.ru.service3.serviceDescription,
                            serviceList: {
                                item1: servicesContent.ru.service3.serviceList.item1,
                                item2: servicesContent.ru.service3.serviceList.item2,
                                item3: servicesContent.ru.service3.serviceList.item3,
                                item4: servicesContent.ru.service3.serviceList.item4,
                                item5: servicesContent.ru.service3.serviceList.item5,
                                item6: servicesContent.ru.service3.serviceList.item6,
                                item7: servicesContent.ru.service3.serviceList.item7,
                            }
                            // servicePlan: {
                            //     item1: servicesContent.ru.service3.servicePlan.item1,
                            //     item2: servicesContent.ru.service3.servicePlan.item2,
                            //     item3: servicesContent.ru.service3.servicePlan.item3,
                            //     item4: servicesContent.ru.service3.servicePlan.item4,
                            //     item5: servicesContent.ru.service3.servicePlan.item5
                            // }
                        },
                        service4: {
                            serviceDescription: servicesContent.ru.service4.serviceDescription,
                            serviceList: {
                                item1: servicesContent.ru.service4.serviceList.item1,
                                item2: servicesContent.ru.service4.serviceList.item2,
                                item3: servicesContent.ru.service4.serviceList.item3,
                                item4: servicesContent.ru.service4.serviceList.item4,
                                item5: servicesContent.ru.service4.serviceList.item5,
                                item6: servicesContent.ru.service4.serviceList.item6,
                                item7: servicesContent.ru.service4.serviceList.item7,
                                item8: servicesContent.ru.service4.serviceList.item8,
                                item9: servicesContent.ru.service4.serviceList.item9,
                                item10: servicesContent.ru.service4.serviceList.item10,
                                item11: servicesContent.ru.service4.serviceList.item11,
                            }
                        },
                        service5: {
                            serviceDescription: servicesContent.ru.service5.serviceDescription,
                            serviceList: {
                                item1: servicesContent.ru.service5.serviceList.item1,
                                item2: servicesContent.ru.service5.serviceList.item2,
                                item3: servicesContent.ru.service5.serviceList.item3,
                                item4: servicesContent.ru.service5.serviceList.item4,
                                item5: servicesContent.ru.service5.serviceList.item5,
                                item6: servicesContent.ru.service5.serviceList.item6
                            }
                        },

                    },
                    about: {
                        aboutHeader: aboutHeader.ru.aboutHeader,
                        aboutNumbers: {
                            block1: {
                                number: aboutNumbers.ru.block1.number,
                                desc: aboutNumbers.ru.block1.desc
                            },
                            block2: {
                                number: aboutNumbers.ru.block2.number,
                                desc: aboutNumbers.ru.block2.desc
                            },
                            block3: {
                                number: aboutNumbers.ru.block3.number,
                                desc: aboutNumbers.ru.block3.desc
                            }
                        },
                        aboutDesc: aboutInformation.ru.aboutDesc,
                        aboutAdventage1: aboutInformation.ru.adventage1,
                        aboutAdventage2: aboutInformation.ru.adventage2,
                        aboutAdventage3: aboutInformation.ru.adventage3
                    },
                    crew: {
                        crewHeader: crewContent.ru.crewHeader,
                        crewDesc1: crewContent.ru.crewDesc1,
                        crewDesc2: crewContent.ru.crewDesc2
                    },

                    formText: {
                        formHeader: formText.ru.formHeader,
                        name: formText.ru.name,
                        phone: formText.ru.phone,
                        mail: formText.ru.mail,
                        comment: formText.ru.comment,
                        confidatial: formText.ru.confidatial,
                        submit: formText.ru.submit
                    },
                    footer: {
                        nav_1: footer.ru.nav_1,
                        nav_2: footer.ru.nav_2,
                        nav_3: footer.ru.nav_3,
                        mail: footer.ru.mail,
                        tel: footer.ru.tel,
                    },
                    menu: {
                        menu_item_1: menu.ru.menu_item_1,
                        menu_item_2: menu.ru.menu_item_2,
                        menu_item_3: menu.ru.menu_item_3,
                        menu_item_4: menu.ru.menu_item_4,
                        menu_item_5: menu.ru.menu_item_5
                    },
                    casesContent: {
                        tab_1: {
                            case_1: {
                                header: casesContent.ru.web.case_1.header,
                                about: casesContent.ru.web.case_1.about,
                                profile: casesContent.ru.web.case_1.profile,
                                description: casesContent.ru.web.case_1.description,
                                goals: casesContent.ru.web.case_1.goals,
                                results: casesContent.ru.web.case_1.results,
                                total: casesContent.ru.web.case_1.total,
                                logo: casesContent.ru.web.case_1.logo,
                                creo_1: casesContent.ru.web.case_1.creo_1,
                                creo_2: casesContent.ru.web.case_1.creo_2,
                            },
                            case_2: {
                                header: casesContent.ru.web.case_2.header,
                                about: casesContent.ru.web.case_2.about,
                                profile: casesContent.ru.web.case_2.profile,
                                description: casesContent.ru.web.case_2.description,
                                goals: casesContent.ru.web.case_2.goals,
                                results: casesContent.ru.web.case_2.results,
                                total: casesContent.ru.web.case_2.total,
                                logo: casesContent.ru.web.case_2.logo,
                                creo_1: casesContent.ru.web.case_2.creo_1,
                                creo_2: casesContent.ru.web.case_2.creo_2,
                            },
                            case_3: {
                                header: casesContent.ru.web.case_3.header,
                                about: casesContent.ru.web.case_3.about,
                                profile: casesContent.ru.web.case_3.profile,
                                description: casesContent.ru.web.case_3.description,
                                goals: casesContent.ru.web.case_3.goals,
                                results: casesContent.ru.web.case_3.results,
                                total: casesContent.ru.web.case_3.total,
                                logo: casesContent.ru.web.case_3.logo,
                                creo_1: casesContent.ru.web.case_3.creo_1,
                            }
                        },
                        tab_2: {
                            case_1: {
                                header: casesContent.ru.design.case_1.header,
                                about: casesContent.ru.design.case_1.about,
                                profile: casesContent.ru.design.case_1.profile,
                                description: casesContent.ru.design.case_1.description,
                                goals: casesContent.ru.design.case_1.goals,
                                results: casesContent.ru.design.case_1.results,
                                total: casesContent.ru.design.case_1.total,
                                logo: casesContent.ru.design.case_1.logo,
                                creo_1: casesContent.ru.design.case_1.creo_1,
                            },
                            case_2: {
                                header: casesContent.ru.design.case_2.header,
                                about: casesContent.ru.design.case_2.about,
                                profile: casesContent.ru.design.case_2.profile,
                                description: casesContent.ru.design.case_2.description,
                                goals: casesContent.ru.design.case_2.goals,
                                results: casesContent.ru.design.case_2.results,
                                total: casesContent.ru.design.case_2.total,
                                logo: casesContent.ru.design.case_2.logo,
                                creo_1: casesContent.eng.design.case_2.creo_1,

                            },
                            case_3: {
                                header: casesContent.ru.design.case_3.header,
                                about: casesContent.ru.design.case_3.about,
                                profile: casesContent.ru.design.case_3.profile,
                                description: casesContent.ru.design.case_3.description,
                                goals: casesContent.ru.design.case_3.goals,
                                results: casesContent.ru.design.case_3.results,
                                total: casesContent.ru.design.case_3.total,
                                logo: casesContent.ru.design.case_3.logo,
                            }
                        },
                        tab_3: {
                            case_1: {
                                header: casesContent.ru.smm.case_1.header,
                                about: casesContent.ru.smm.case_1.about,
                                profile: casesContent.ru.smm.case_1.profile,
                                description: casesContent.ru.smm.case_1.description,
                                goals: casesContent.ru.smm.case_1.goals,
                                results: casesContent.ru.smm.case_1.results,
                                total: casesContent.ru.smm.case_1.total,
                                logo: casesContent.ru.smm.case_1.logo,
                                creo_1: casesContent.ru.smm.case_1.creo_1,
                                creo_2: casesContent.ru.smm.case_1.creo_2,
                            },

                            case_2: {
                                header: casesContent.ru.smm.case_2.header,
                                about: casesContent.ru.smm.case_2.about,
                                profile: casesContent.ru.smm.case_2.profile,
                                description: casesContent.ru.smm.case_2.description,
                                goals: casesContent.ru.smm.case_2.goals,
                                results: casesContent.ru.smm.case_2.results,
                                total: casesContent.ru.smm.case_2.total,
                                logo: casesContent.ru.smm.case_2.logo,
                                creo_1: casesContent.ru.smm.case_2.creo_1,
                                creo_2: casesContent.ru.smm.case_2.creo_2,
                            },
                            case_3: {
                                header: casesContent.ru.smm.case_3.header,
                                about: casesContent.ru.smm.case_3.about,
                                profile: casesContent.ru.smm.case_3.profile,
                                description: casesContent.ru.smm.case_3.description,
                                goals: casesContent.ru.smm.case_3.goals,
                                results: casesContent.ru.smm.case_3.results,
                                total: casesContent.ru.smm.case_3.total,
                                logo: casesContent.ru.smm.case_3.logo,
                                creo_1: casesContent.ru.smm.case_3.creo_1,
                            },

                        },
                        tab_4: {
                            case_1: {
                                header: casesContent.ru.adv.case_1.header,
                                about: casesContent.ru.adv.case_1.about,
                                profile: casesContent.ru.adv.case_1.profile,
                                description: casesContent.ru.adv.case_1.description,
                                goals: casesContent.ru.adv.case_1.goals,
                                results: casesContent.ru.adv.case_1.results,
                                total: casesContent.ru.adv.case_1.total,
                                logo: casesContent.ru.adv.case_1.logo,
                                creo_1: casesContent.ru.adv.case_1.creo_1,
                                creo_2: casesContent.ru.adv.case_1.creo_2,
                            },
                            case_2: {
                                header: casesContent.ru.adv.case_2.header,
                                about: casesContent.ru.adv.case_2.about,
                                profile: casesContent.ru.adv.case_2.profile,
                                description: casesContent.ru.adv.case_2.description,
                                goals: casesContent.ru.adv.case_2.goals,
                                results: casesContent.ru.adv.case_2.results,
                                total: casesContent.ru.adv.case_2.total,
                                logo: casesContent.ru.adv.case_2.logo,
                                creo_1: casesContent.ru.adv.case_2.creo_1,
                            }
                        },
                        tab_5: {
                            case_1: {
                                header: casesContent.ru.video.case_1.header,
                                about: casesContent.ru.video.case_1.about,
                                profile: casesContent.ru.video.case_1.profile,
                                description: casesContent.ru.video.case_1.description,
                                goals: casesContent.ru.video.case_1.goals,
                                results: casesContent.ru.video.case_1.results,
                                total: casesContent.ru.video.case_1.total,
                                logo: casesContent.ru.video.case_1.logo,
                                creo_1: casesContent.ru.video.case_1.creo_1,

                            },
                            case_2: {
                                header: casesContent.ru.video.case_2.header,
                                about: casesContent.ru.video.case_2.about,
                                profile: casesContent.ru.video.case_2.profile,
                                description: casesContent.ru.video.case_2.description,
                                goals: casesContent.ru.video.case_2.goals,
                                results: casesContent.ru.video.case_2.results,
                                total: casesContent.ru.video.case_2.total,
                                logo: casesContent.ru.video.case_2.logo,
                                creo_1: casesContent.ru.video.case_2.creo_1,
                            },
                            case_3: {
                                header: casesContent.ru.video.case_3.header,
                                about: casesContent.ru.video.case_3.about,
                                profile: casesContent.ru.video.case_3.profile,
                                description: casesContent.ru.video.case_3.description,
                                goals: casesContent.ru.video.case_3.goals,
                                results: casesContent.ru.video.case_3.results,
                                total: casesContent.ru.video.case_3.total,
                                logo: casesContent.ru.video.case_3.logo,
                                creo_1: casesContent.ru.video.case_3.creo_1,
                                creo_2: casesContent.ru.video.case_3.creo_2,
                            }
                        },
                        headers: {
                            summary: casesContent.ru.headers.summary,
                            profile: casesContent.ru.headers.profile,
                            description: casesContent.ru.headers.description,
                            goals: casesContent.ru.headers.goals,
                            results: casesContent.ru.headers.results,
                            total: casesContent.ru.headers.total,
                            casesDetails: casesContent.ru.headers.casesDetails

                        }

                    }
                }
            }
        }
    })