const  aboutNumbers = {
    ru: {
        block1: {
            number: "1 670 000",
            desc: "слов написал копирайтер"
        },
        block2: {
            number: "10 000",
            desc: "кружек кофе выпито перед дедлайном"
        },
        block3: {
            number: "1 000 000",
            desc: "проехали мышки на столах команды"
        } 
    },
    eng: {
        block1: {
            number: "1 670 000",
            desc: "words by our copyrighters "
        },
        block2: {
            number: "10 000",
            desc: "cups of coffee before deadlines "
        },
        block3: {
            number: "1 000 000",
            desc: "of computer mice movements on our team’s tables"
        } 
    }
}

export default aboutNumbers